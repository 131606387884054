import { useVideoContent } from '../../../store/firstScreenLoadSlice';
import { Loader } from './Loader/Loader';
import styles from './FirstScreen.module.scss';
import { CSSProperties, useEffect, useState } from 'react';
import clsx from 'clsx';
import { CreateButton } from './CreateAcountButton/CreateAccountButton';

const DURATION = 4000;

export const FirstScreen = () => {
    const isLoading = useVideoContent((state) => state.isLoading);
    const setVideoLoaded = useVideoContent((state) => state.setVideoLoaded);
    const [slide, setSlide] = useState(0);

    const next = () => setSlide(Math.min(slide + 1, 2));
    const prev = () => setSlide(Math.max(slide - 1, 0));

    useEffect(() => {
        if (!isLoading) {
            if (slide < 2) {
                const id = setTimeout(() => {
                    setSlide(slide + 1);
                }, DURATION);
                return () => {
                    clearTimeout(id);
                };
            }
        }
    }, [slide, isLoading]);

    return (
        <div
            className={styles.root}
            style={
                {
                    '--offset': `-${slide * 100}vw`,
                    '--duration': `${DURATION}ms`,
                } as CSSProperties
            }
        >
            <div className={styles.progressBar}>
                <div
                    className={clsx(
                        styles.progressItem,
                        slide === 0
                            ? styles.progress
                            : slide === 1 || slide === 2
                              ? styles.active
                              : null,
                    )}
                />
                <div
                    className={clsx(
                        styles.progressItem,
                        slide === 1 ? styles.progress : slide === 2 ? styles.active : null,
                    )}
                />
                <div className={clsx(styles.progressItem, slide === 2 ? styles.progress : null)} />
            </div>
            <div className={styles.slides}>
                <div className={styles.slide}>
                    <div className={styles.storyWrapper}>
                        <div className={styles.mediaWrapper}>
                            <video
                                className={styles.media}
                                poster="/media/mutagen.jpg"
                                autoPlay
                                muted
                                loop
                                playsInline
                                onCanPlayThrough={() => setVideoLoaded(0)}
                            >
                                <source src="/media/mutagen.mp4" type="video/mp4" />
                                <source src="/media/mutagen.webm" type="video/webm" />
                                <track kind="captions" label="Mutagen" />
                                Your browser doesn&apos;t support HTML5 video tag.
                            </video>
                        </div>

                        <h2>FARM 🧪 MTGN</h2>
                        <p>
                            Essential in-game points. Tap, invite friends, fulfil tasks and farm
                            your retrodrop balance.
                        </p>
                    </div>
                    <div className={styles.prev} onClick={prev} />
                    <div className={styles.next} onClick={next} />
                </div>
                <div className={styles.slide}>
                    <div className={styles.storyWrapper}>
                        <div className={styles.mediaWrapper}>
                            <video
                                className={styles.media}
                                poster="/media/roach.jpg"
                                autoPlay
                                muted
                                loop
                                playsInline
                                onCanPlayThrough={() => setVideoLoaded(1)}
                            >
                                <source src="/media/roach.mp4" type="video/mp4" />
                                <source src="/media/roach.webm" type="video/webm" />
                                <track kind="captions" label="Roach egg" />
                                Your browser doesn&apos;t support HTML5 video tag.
                            </video>
                        </div>

                        <h2>MINT 🦠 ROACH</h2>
                        <p>
                            Incubate your genesis Roach and get ready to train, breed and customize
                            your lucky runners.
                        </p>
                    </div>
                    <div className={styles.prev} onClick={prev} />
                    <div className={styles.next} onClick={next} />
                </div>
                <div className={styles.slide}>
                    <div className={styles.storyWrapper}>
                        <div className={styles.mediaWrapper}>
                            <video
                                className={styles.media}
                                poster="/media/city.jpg"
                                autoPlay
                                muted
                                loop
                                playsInline
                                onCanPlayThrough={() => setVideoLoaded(2)}
                            >
                                <source src="/media/city.mp4" type="video/mp4" />
                                <source src="/media/city.webm" type="video/webm" />
                                <track kind="captions" label="city" />
                                Your browser doesn&apos;t support HTML5 video tag.
                            </video>
                        </div>

                        <h2>WIN 🏁 BIG</h2>
                        <p>Match up against real opponents and win unique rewards!</p>

                        <div className={styles.buttonContainer}>
                            <CreateButton />
                        </div>
                    </div>
                    <div className={styles.prev} onClick={prev} />
                    <div className={styles.next} onClick={next} />
                </div>
            </div>

            {isLoading && (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            )}
        </div>
    );
};
